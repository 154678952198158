<template>
  <OnboardPage 
    :content="content"
  >
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <p style="margin-bottom:20px;">{{ heading }}</p>
    <p><i class="fas fa-lock"></i> secure pci compliance through <a href="https://stripe.com/docs/security/stripe" target="_blank">stripe</a></p>
    <form @submit.prevent="onSubmit">
      <div
        v-if="business.stripe_card_info == null"
        class="card-holder"
      >
      <Card
        :class="complete"
        :stripe="stripe_token"
        @change="complete = $event.complete"
      />
      </div>
      <span 
        v-else
        class="tag is-medium"
      >
        {{ business.stripe_card_info }}
        <button 
          @click.prevent="deleteCard"
          class="delete is-small"></button>
      </span> 
      <BaseSubmit
        v-if="business.stripe_card_info == null"
        label="Fund deal"
        :disabled="!complete"
        :submitting="submitting"
      />
    </form>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import Notification from "@/components/Notification.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import { Card, createToken } from "vue-stripe-elements-plus";

export default {
  components: {
    OnboardPage,
    Notification,
    Card,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      complete: false,
      error: null,
      submitting: false,
      business: {
        stripe_card_info: null,
        refill_amount: 2500,
        refill_trigger: 1000
      },
      content: {
        page: 'organization-payment-method',
        step: 4,
        title: 'Payment Details',
        subtitle: '',
        sideComponentText: 'Attract more customers. Only pay when a customer purchases and claims the deal.',
        nextPage: "organization-thank-you",
        showBack: true,
      }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    heading: function() {
      return `You are about to prepay $${this.dollar_amount} to fund deals and attract customers`
    },
    dollar_amount: function() {
      return this.business.refill_amount/100
    },
    stripe_token: function() {
      let url = window.location.hostname;
       if (url == "www.localloot.app" || url == "localloot.app" || url == "deals.localloot.app") {
         return "pk_live_fW6GVHS1Kw3hbXMSRk4fmsEm00DvJ52YZa"
       } else {
        return "pk_test_zG5qg6LIZ7OKPI2xFOzN3Vgt00WzF7WjMy"
       }
    },
  },
  mounted() {
    
    if (this.currentBusiness && this.currentBusiness.slug) {
      if (this.currentBusiness.is_onboarded == true) {
         this.$router.push('/deals/user/my-location/100')
      } else {
      
      this.business = this.currentBusiness
      if (this.business.stripe_card_info != '') {
        this.complete = true
      }
      }
    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    next() {
      this.$router.push('/o/' + this.content.nextPage)
    },
    deleteCard() {
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
      }
      this.$store.dispatch("removeCreditCard", params)
        .then(() => {
          this.business.stripe_card_info = null
          this.complete = false
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    },
    onSubmit() {
      this.submitting = true;
  
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {}
      }

      createToken()
      .then((result) => {
        params["organization"]["stripe_token_id"] = result.token.id     
        params["organization"]["purchase_amount"] = this.business.refill_amount

        this.$store.dispatch("updateBusiness", params)
          .then(() => {
            this.next()
          })
          .catch(error => {
            if (error instanceof AuthError || error instanceof NetworkError) {
              throw error
            } else {
              console.log(error)
              this.error = error.message
            }
          })
          .finally(() => {
            this.submitting = false
          })
      })
      .catch(error => {
        this.error = error  
      })
    }
  }
}
</script>

<style scoped>
.card-holder {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 10px 0;
}
</style>
